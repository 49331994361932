import { defineComponent, ref, h, computed } from 'vue';
import type { FunctionalComponent, PropType } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import { NotificationColor, NotificationType } from 'api/notifications/models';
import { MainColor, Size } from 'core/styles';
import Icon from 'components/Icon';
import Avatar from 'components/Avatar';
import {
  getBadgeIconByType,
  getColor,
  getIconByType,
  hasAvatar as notificationHasAvatar,
} from 'core/helpers/notifications';
import type { INotificationItem } from './Notifications';

const NotificationsIcon: FunctionalComponent<{ type: string, data?: any }> = (props) => {
  const color = computed(() => getColor(props.type));

  const icon = computed(() => getIconByType(props.type));

  const hasAvatar = computed(() => notificationHasAvatar({
    ...props.data,
    type: props.type,
  }));

  const badgeIcon = computed(() => getBadgeIconByType(props.type));

  return h('div', {
    class: {
      'notifications-item__icon': true,
      'notifications-item__icon--green': color.value === NotificationColor.Green,
      'notifications-item__icon--yellow': color.value === NotificationColor.Yellow,
    },
  }, hasAvatar.value ? [
    h(Avatar, {
      user: props.data.object,
      size: Size.Xs,
    }),
    badgeIcon.value ? h('div', {
      class: [
        'notifications-item__icon__badge',
        { [`notifications-item__icon__badge--${badgeIcon.value.color}`]: props.type !== NotificationType.NewVisitor },
        { 'notifications-item__icon__badge--visitor': props.type === NotificationType.NewVisitor },
      ],
    }, h(Icon, {
      type: badgeIcon.value.type,
      name: badgeIcon.value.name,
      color: MainColor.White,
      size: Size.Xs,
    })) : undefined,
  ] : icon.value ? h(Icon, {
    type: icon.value.type,
    name: icon.value.name,
    color: MainColor.White,
  }) : undefined);
};

const NotificationsItem = defineComponent({
  props: {
    item: {
      type: Object as PropType<INotificationItem>,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter();
    const { t } = useI18n();

    const EXTERNAL_URL_RE = /^https?:/i;

    const elementRef = ref<HTMLElement>();

    const message = computed(() => (
      (!Array.isArray(props.item.data) && props.item.data?.text)
      ? props.item.data.text : t(props.item.type.replaceAll('\\', '.'), props.item.data)));

    return () => h('div', {
      ref: elementRef,
      class: {
        'notifications-item': true,
      },
      onClick: () => {
        const action = props.item.data?.action;
        if (action) {
          if (props.item.data?.object?.id) {
            router.push(`/user/${props.item.data?.object?.id}`);
            return;
          }

          if (EXTERNAL_URL_RE.test(action)) {
            window.open(action, '_blank');
          } else {
            router.push(action);
          }
        }
      },
    }, [
      h(NotificationsIcon, {
        type: props.item.type,
        data: props.item.data,
      }),

      h('div', {
        class: 'notifications-item__content',
      }, [
        h('div', { class: 'notifications-item__content-wrapper' }, message.value),
        h(
          'span',
          props.item.dateFormat
            ? dayjs(props.item.created_at).locale('ru').format(props.item.dateFormat)
            : dayjs(props.item.created_at).locale('ru').fromNow(),
        ),
      ]),

      h('div', {
        class: 'notifications-item__action',
      }, !props.item.read_at ? h('div', { class: 'notifications-item__dot' }) : undefined),
    ]);
  },
});

export default NotificationsItem;
