<template>
  <div
    ref="target"
    class="list-loader"
  >
    <div
      v-if="isLoading"
      class="list-loader__loading-text"
    >
      {{ $t('components.listLoader.loading') }}
    </div>
    <div
      v-else-if="!hasNext"
      class="list-loader__loading-end"
    >
      {{ endTitle }}
    </div>
  </div>
</template>

<script lang="ts" src="./ListLoader.ts" />
<style lang="scss" src="./listLoader.scss" />
