import { defineComponent, computed, ref, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';

import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { useI18n } from 'vue-i18n';

const ListLoader = defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    hasNext: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['load'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const target = ref();

    const currentSex = computed(() =>
      store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]?.gender);

    const observer = new IntersectionObserver(onIntersect, {
      rootMargin: '0px',
      threshold: 0,
    });
    onMounted(() => {
      observer.observe(target.value);
    });
    onBeforeUnmount(() => {
      observer.unobserve(target.value);
    });

    function onIntersect(entry: IntersectionObserverEntry[]) {
      if (!props.hasNext || props.isLoading || !entry[0].isIntersecting) return;
      emit('load');
    }

    const endTitle = computed(
      () => t(`components.listLoader.end.${currentSex.value || 'title'}`),
    );

    return {
      currentSex,
      endTitle,
      target,
    };
  },
});

export default ListLoader;
