import { defineComponent, onBeforeMount, onBeforeUnmount, computed, h } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import dayjs from 'dayjs';
import { INotification } from 'api/notifications/models';
import { MainColor, Size } from 'core/styles';
import IconBtn from 'components/IconBtn';
import Icon, { IconName, IconType } from 'components/Icon';
import { ModalHeader, ModalHeaderDirection } from 'components/Modal';
import Txt, { TxtType } from 'components/Txt';
import {
  useNotificationsGetter,
  NotificationsGetter,
  useNotificationsAction,
  NotificationsAction,
} from 'store/notifications';
import { RouteNames } from 'router/names';
import ListLoader from 'components/ListLoader';

import './notifications.scss';
import { useStore } from 'vuex';
import { useFetching } from 'composables/fetching';
import { useApiErrors } from 'composables/apiErrors';
import { NOTIFICATIONS_STORE_KEY } from 'store/notifications/constants';
import NotificationsItem from './NotificationsItem';
import SvgIcon from './assets/empty-notifications.svg';

export type INotificationItem = INotification & {
  dateFormat?: string
}

const Notifications = defineComponent({
  name: RouteNames.Notifications,
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const { isFetching, fetch } = useFetching();
    const { showError } = useApiErrors();

    const loadMore = () => {
      fetch(store.dispatch(`${NOTIFICATIONS_STORE_KEY}/${NotificationsAction.ListNotifications}`, true))
        .catch((e) => showError(e));
    };
    const hasNext = computed(() => store.getters[`${NOTIFICATIONS_STORE_KEY}/${NotificationsGetter.HasNext}`]);

    const { dispatch: markAsRead } = useNotificationsAction<number[]>(NotificationsAction.MarkAsRead);

    const { result: resultItems } = useNotificationsGetter<INotificationItem[]>(NotificationsGetter.Notifications);
    const { result: hasUnviewed } = useNotificationsGetter<INotificationItem[]>(NotificationsGetter.HasUnviewed);

    const computedItems = computed(() => {
      if (!resultItems.value) return [];

      const result: Array<[string, INotificationItem[]]> = [];
      const newItems = [] as INotificationItem[];
      const todayItems = [] as INotificationItem[];
      const yesterdayItems = [] as INotificationItem[];
      const restItems = [] as INotificationItem[];

      resultItems.value.forEach(item => {
        const createdAt = dayjs(item.created_at).locale('ru');
        if (createdAt.isToday()) {
          todayItems.push(item);
        } else if (createdAt.isYesterday()) {
          yesterdayItems.push({ ...item, dateFormat: `${t('pages.notifications.yesterday')}, HH:mm` });
        } else {
          restItems.push({ ...item, dateFormat: 'DD.MM.YYYY, HH:mm' });
        }
      });

      if (newItems.length > 0) {
        result.push([t('pages.notifications.new'), newItems]);
      }
      if (todayItems.length > 0) {
        result.push([t('pages.notifications.today'), todayItems]);
      }
      if (yesterdayItems.length > 0) {
        result.push([t('pages.notifications.yesterday'), yesterdayItems]);
      }
      if (restItems.length > 0) {
        result.push([t('pages.notifications.before'), restItems]);
      }

      return result;
    });

    onBeforeMount(async () => {
      document.body.classList.add('auth-layout--body');
    });
    onBeforeUnmount(() => {
      document.body.classList.remove('auth-layout--body');
    });

    async function onClick(item: INotificationItem) {
      if (item.data?.link) {
        router.push(item.data.link);
      }
    }

    return () => h('main', {
      class: 'notifications',
    }, [
      h(ModalHeader, {
        title: t('pages.notifications.title'),
        dir: ModalHeaderDirection.Rtl,
        relative: true,
        onClose: () => router.back(),
      }, {
        action: () => h(IconBtn, {
          title: t('pages.notifications.markAllAsRead'),
          class: 'notifications__check-icon',
          size: Size.M,
          onClick: markAsRead,
        }, {
          default: () => h(Icon, {
            type: IconType.Fill,
            name: IconName.Mark,
            size: Size.M,
            color: !hasUnviewed.value ? MainColor.Grey5 : MainColor.Black2,
          }),
        }),
      }),

      !computedItems.value.length
        ? h('div', {
          class: 'notifications__empty',
        }, [
          h('img', { src: SvgIcon }),
          h(Txt, { type: TxtType.Heading2, class: 'mt-3 mx-5' }, [t('pages.notifications.empty')]),
        ])
        : computedItems.value.map(([title, items]) => [
        h('div', {
          class: 'notifications__title',
        }, title),

        items.map((item) => h(NotificationsItem, {
          item,
          onClick: () => onClick(item),
        })),
      ]),
      // @ts-ignore
      computedItems.value.length ? h(ListLoader, {
        hasNext: hasNext.value,
        isLoading: isFetching.value,
        onLoad: loadMore,
      }) : undefined,
    ]);
  },
});

export default Notifications;
